
import React, { useEffect } from 'react';
import './FormAsesorias.css'
import { Radio, Form, Button, Input, ConfigProvider, Space } from 'antd';
import { UploadFile } from './UploadFiles/OclusalSuperior';
import { Oclusalinferior } from './UploadFiles/Oclusalinferior';
import { FrontalSinAlineador } from './UploadFiles/FrontalSinAlineador'; 
import { FrontalConAlineador } from './UploadFiles/FrontalConAlineador';
import { LateralDerechaOclusion } from './UploadFiles/LateralDerechaOclusion'; 
import { LateralizquierdaOclusion } from './UploadFiles/LateralizquierdaOclusion';
import { PanoramicaCraneo } from './UploadFiles/PanoramicaCraneo';
import { LateralCraneo } from './UploadFiles/LateralCraneo';
import { AreaMotivoConsulta } from './UploadFiles/AreaMotivoConsulta';
import { CalendlyOneWrapp } from './CalendlyOneWrapp';
import { CalendlyAsesoriasPaga } from './CalendlyAsesoriasPaga';
import { CalendarPlanificacion17 } from './CalendarPlanificacion17';
import { CalendarPlanificacion14 } from './CalendarPlanificacion14';
import { Testupload } from './Testupload';
import {useState} from 'react';
import alerticon from './alert-icon.svg';
import iconImportant from './assets/icon-alert-important.svg';
import AlertError from './assets/status-icon.svg';
import Swal from 'sweetalert2';

const { TextArea } = Input;

function FormAsesorias () {
   
    const [value, setValue] = useState(1);
    const [values, setValues] = useState(1);

    //cambia el estado del campo requerido EL CASO SE ECNUENTRA
    const [componentNoRequerid, setComponentNoRequerid] = useState(true);

    //Tipo de asesoria 
    const [ tipoDeAsesoriaApiPost, setTipoDeAsesoriaApiPost] = useState("")

    //cambia el estado del campo requerido EL CASO EN ALINEADOR
    const [inputAlineador, setInputAlineador] = useState(true);

     //cambia el estado del campo requerido TIPO DE ASESORÍA
     const [inputTasesoria, setInputTasesoria] = useState(true);

      //cambia el estado del campo requerido NECESITA FACTURA
      const [inputNecesitaFactura, setInputNecesitaFactura] = useState(true);

    //muestra el grupo de opciones
    const [componentDisabled1, setComponentDisabled1] = useState(null);

    //Si el caso se encuentra en boca
    const [casoEnBoca, setCasoEnBoca] = useState(null);

    //Asesoria clinica
    const [asesoriaClinica, setAsesoriaClinica] = useState(null);

    //Asesoria Planificacion
    const [asesoriaPlanificacion, setAsesoriaPlanificacion] = useState(null);

    //Para activar upload img
    const [activeUploadImg, setActiveUploadImg] = useState(null);

     //Activa / Oculta el formulario entero
     const [activForm, setActivForm] = useState(null);

    //Activa la seccion de calendly
    const [activCalendly, setActivCalendly] = useState(null);

    //Estado que muestra u oculta el area  de factura
    const [facturaIs, setFacturaIs] = useState(false);

    //Estado que envia el dato a Airtable si necesita o no factura
    const [facturaNeed, setFacturaNeed] = useState(false);

    //Ya tomo su primera acesoría, tomamos este dato para mandarlo a Airtable
    const [firstCallStatus, setFirstCallStatus] = useState(null);

    // Ya tiene una planificación
    const [tratmentStatus, setTratmentStatus] = useState(null);

    //array para setear tipoAsesoria
    const [tipoAsesoriaArr, setTipoAsesoriaArr] = useState("");

    //Recibe URL imagen 1
    const [oclusalSuperior, setOclusalSuperior] = useState('');

    //Recibe URL imagen Oclusal inferior
    const [oclusalInferior, setOclusalInferior] = useState('');

    //Recibe URL imagen Frontal Sin Alineador
    const [frontalSinAlineador, setFrontalSinAlineador] = useState('');

    //Recibe URL imagen Frontal Con alineador
    const [frontalConAlineador, setFrontalConAlineador] = useState('');

    //Recibe URL imagen Lateral derecha
    const [lateralDerecho, setLateralDerecho] = useState('');

    //Recibe URL imagen Lateral Izquierda
    const [lateralIzquierdo, setLateralIzquierdo] = useState('');

    //Panorámica de cráneo
    const [panoramicaCraneo, setPanoramicaCraneo] = useState('');
     
    //Recibe imagen Lateral de cráneo
    const [lateralCraneo, setLateralCraneo] = useState('');

    //Recibe imagen Área de consulta
    const [areaConsulta, setAreaConsulta] = useState('');

    //Active lateral craneo
    const [activeLateralCraneo, setActiveLateralCraneo] = useState(false);

    //Muestro el head con infromacion gratuita o de paga
    const [headType, setHeadType] = useState(false);

    //Guarda el valo en boca para luego utilizarlo como parametro para mostrar componente de subidad de archivos

const [isTrueSiEnBoca, setIsTrueSiEnBoca] = useState(false);

    //Guarda el valo true del radio para luego utilizarlo como parametro para mostrar componente de subidad de archivos
const [isTrueUpload, setIsTrueUpload] = useState(null);

//Guarda en un estado el dato de la URL
const [firstCallUrl, setFirstCallUrl] = useState(null);

//Guarda en un estado tratment Id
const [tratmentIdUrl, setTratmentIdUrl] = useState(null);

//Guarda si tiene o no planificación
const [hasPlanificationUrl, setHasPlanificationUrl] = useState(true);

//Guarda admin Id
const [adminIdUrl, setAdminIdUrl] = useState(null);

//Tomo asesoría gratuita ?
const [seCobra, setSeCobra] = useState(false);

//Estados para ver que calendly mostrar
const [calendlyGratuito, setCalendlyGratuito] = useState(false);
const [calendlyPaga, setCalendlyPaga] = useState(false)
const [calendlyPlanificacin17, setCalendlyPlanificacin17] = useState(false);
const [calendlyPlanificacin14, setCalendlyPlanificacin14] = useState(false);

//activa o no el calendly
const [planificacin17, setPlanificacin17] = useState(false);
const [planificacin14, setPlanificacin14] = useState(false);
const [planificacin34, setPlanificacin34] = useState(false);

//Estado activa el botón de envíar el form
const [needActive, setnNedActive]= useState(true);

const [textoBtnSend, setTextoBtnSend]= useState("Completa todos los campos")

//Estado para guardar el true o false de first-call de la URL

const [isFirstCall, setIsFirstCall]= useState(null)


//Este dato no sisrve pare definir si no esta en boca pero si es asesoría clinica 
const [noEnBocaClinica, setNoEnBocaClinica] = useState(null);


    //console.log(datosForm);
    //console.log(oclusalInferior);
    //console.log("aqui esta la URL de la imagen 1");

    //Recibiendo URL de Oclusal Superior
    const URLimage = (imagenURL) => {
      setOclusalSuperior({uno:imagenURL})

      return imagenURL > 1; 
    }
    
    //Recibiendo URL de Oclusal Inferior
    const URLOclusalinferior = (imagenURL) => {
      setOclusalInferior(imagenURL)

      return imagenURL > 1;
    }

    //Recibiendo URL de Frontal sin alineador
    const URLFrontalSinAlineador = (imagenURL) => {
      setFrontalSinAlineador(imagenURL)
    }

    //Recibiendo URL de Frontal Con alineador
    const URLFrontalConAlineador = (imagenURL) => {
      setFrontalConAlineador(imagenURL)
    }

    //Recibiendo URL de Lateral Derecho Oclusion
    const URLLateralDerechaOclusion = (imagenURL) => {
      setLateralDerecho(imagenURL)
    }

    //Recibiendo URL de Lateral izquierda en oclusión
    const URLLateralizquierdaOclusion = (imagenURL) => {
      setLateralIzquierdo(imagenURL)
    }

    //Recibiendo URL de Panorámica de cráneo
    const URLPanoramicaCraneo = (imagenURL) => {
      setPanoramicaCraneo(imagenURL)
    }

    //Recibiendo URL de Lateral de cráneo
    const URLLateralCraneo = (imagenURL) => {
      setLateralCraneo(imagenURL)
    }

    //Recibiendo URL de Area consulta
    const URLAreaMotivoConsulta = (imagenURL) => {
      setAreaConsulta(imagenURL)
    }



     //setea valores en radio buttons
    const seEncuentra = [
      {
        label: 'En boca',
        value: "En boca",
       
      },
      {
        label: 'No está en boca',
        value: "No está en boca",
        
      },
    ];

    //setea valores en radio buttons

    const tipoAsesoria = [
        {
          label: 'Asesoría clínica',
          value: "Asesoría clinica",
        },
        {
          label: 'Asesoría de planificación',
          value: "Asesoría de planificación",
        },
      ];

      //Necesita factura?
    const factura = [
      {
        label: 'Solicitar factura',
        value: "true",
      },
      {
        label: 'No necesito factura',
        value: "false",
      },
    ];
    
    //Condicion segun datos de URL
    // /?treatment_id=1&has_planification=true&admin_id=1&first_call=true
    //0: "?treatment_id=1" Id del tratamiento
    //1: "has_planification=true/false" //SI tiene o no planificación
    //2: "admin_id=1" // Id del administrador
    //3: "first_call=true" // SI ya tomo la asesoría gratuita

    //JS Encryptation hash / Crypto  

    useEffect (() => {

        const getUrl= window.location.search;
       
        const separateUrl = getUrl.split("&");

isAdmin34(separateUrl[2]);
seteandoDatosPairtabel();

        //si ya existe una planificación
        try {
          if (separateUrl[1] === "has_planification=false" ) {
            //defini si los campos serás requeridos
            setComponentNoRequerid(false)
            setInputAlineador(false);
            setInputTasesoria(false);
            setComponentDisabled1("none-div");
            setActiveUploadImg("activemos");
            setFacturaIs("non-div");
            setInputNecesitaFactura(false);

            setTratmentStatus(false)
            setFirstCallStatus(true)
            setnNedActive(true)
            setTextoBtnSend("Completa todos los campos")      
           setSeCobra(false);

           setActiveLateralCraneo(true);

        }else if (separateUrl[2] === "admin_id=17"){
          try {
            setCalendlyPlanificacin17(true)
           
          } catch (error) {
            
          }
         
        }else if (separateUrl[2] === "admin_id=14"){
          
          try {
            setCalendlyPlanificacin14(true)
            //console.log("si paso de aca " + seCobra) 
          } catch (error) {
            
          }
        } 
        else if (separateUrl[3] === "first_call=true" ){
          try {
            ////campo requerido o no
          setInputNecesitaFactura(false);
          setSeCobra(false);
          
          
          } catch (error) {
            
          }
          
     

        } else if (separateUrl[3] === "first_call=false" ){
          try {
             //campo requerido o no
          setInputNecesitaFactura(true);
          ////muestra o no el campo
          //setFirstCallStatus(true)
          setHeadType(true);
          setSeCobra(true);
          
         
      
          
          } catch (error) {
            
            console.log(error)  
          }
         

        } 
        
        else {
          setFacturaIs("mostrar");
          setComponentNoRequerid(true);
          setInputTasesoria(true);
          setInputNecesitaFactura(false);

          setTratmentStatus(true)
          setFirstCallStatus(false)
          //setActivForm("false");
          
          alertaError();
          //alertaPay();
          //alertaSucces();  
          
    } 
          
        } catch (error) {
          
        }
        veamosSisePaga();
       
       
   
    },[firstCallUrl]);

    



    function seteandoDatosPairtabel () {
      const getUrl= window.location.search;

      if (getUrl.includes("&") ){

        const separateUrl = getUrl.split("&");
        setFirstCallUrl(separateUrl[3]);
        //tratment id
        const tratmentSeparate = separateUrl[0].split("=");
        setTratmentIdUrl(tratmentSeparate[1])
        //tratment has planification
        const hasPLanificationData = separateUrl[1].split("=");
        setHasPlanificationUrl(hasPLanificationData[1])
        //tratment admin ID
        const adminIdnData = separateUrl[2].split("=");
        setAdminIdUrl(adminIdnData[1]);
       
        

      } else {
        //console.log("No tiene &&&&&&&&&&&&&&&");
      }
      
    }


   
   
    
  //On change group el caso se encuentra

  function veamosSisePaga() {
    const getUrl= window.location.search;
       
    const separateUrl = getUrl.split("&");

   
      if (separateUrl[3] === "first_call=false" ) {
        try {
         //console.log("si vamos a pagar")
        setSeCobra(true);
            //campo requerido o no
            setInputNecesitaFactura(true);
            ////muestra o no el campo
            //setFirstCallStatus(false)
            setHeadType(true);
            setIsFirstCall(true);
            
        } catch (error) {
          
        }
        
      } else {
        setInputNecesitaFactura(false);
        setSeCobra(false);
        setIsFirstCall(false)
        
      }
  
      esDePagaconPlaificactionFalse();
   
    
  }


  function esDePagaconPlaificactionFalse() {

    //esta constante nos sirve para validar si el se muestra o no calendario de paga aunque sea  admin 34
    const convertBolean2 = hasPlanificationUrl === "false" ? true : false;
    if (isFirstCall && convertBolean2 ) {
      alertaPay();
      setFirstCallStatus(false);
      setTipoDeAsesoriaApiPost("Asesoría clinica")
            
    } else {
      setTipoDeAsesoriaApiPost("Asesoría clinica")
  //console.log(isFirstCall)
  //console.log(convertBolean2)
    }
}
    
  //Admin 34 ?
  function isAdmin34 (dato) {
    if (dato === "admin_id=34") {
      setPlanificacin34(true)
      //console.log("admin 34 es " + planificacin34)
    }
    
  }
 


const onElCasoSeEncuentra = (e) => {
    //console.log('radio checked', e.target.value);
    //setValue(e.target.value);          
    if (e.target.value === "En boca") {
      setCasoEnBoca(e.target.value);
      //setActiveUploadImg("activemos");
      setInputAlineador(true);
      setIsTrueSiEnBoca(true);
    } else if (e.target.value === "No está en boca") {
      setIsTrueSiEnBoca(false);
      setInputAlineador(false);
      setCasoEnBoca(null);
      //Este estado nos ayuda definir si no esta en boca pero si es asesoría clinica
      setNoEnBocaClinica(true);
      
    } 
    setTipoAsesoriaArr(e.target.value);
 
    
};

//On change TIpo de asesoria

const onChanges = (e) => {
  //console.log('radio checked', e.target.value);
  setValues(e.target.value);
  
  if (e.target.value === "Asesoría clinica") {
    //setIsTrueUpload(true);
    setActiveLateralCraneo(true) 
    //setActiveUploadImg("activemos")
    isPaythisEvent();
    //showUploadComponents();
    setIsTrueUpload(true);
    ////campo requerido o no
    //setInputNecesitaFactura(true);
    setPlanificacin34(true)
    setTipoDeAsesoriaApiPost("Asesoría clinica")
    setNoEnBocaClinica(true);
    console.log(noEnBocaClinica);
  
  } else  if (e.target.value === "Asesoría de planificación") {
    //setActiveUploadImg("activemos")
    
    setActiveLateralCraneo(false)
    ////campo requerido o no
    setInputNecesitaFactura(false);
    //muestra o no el campo -
    setFirstCallStatus(true);
    setIsTrueUpload(true);
    setTipoDeAsesoriaApiPost("Asesoría de planificación")

    setNoEnBocaClinica(false);
  } 

};

    // Esta funcion se ejecuta para mostrar popup si es una planbificación que se debe cobrar
    function isPaythisEvent() {
      const getUrl= window.location.search;
      const separateUrl = getUrl.split("&");
      //console.log("se esta ejecutando")

      if (separateUrl[3] === "first_call=false") {
        alertaPay();
         ////campo requerido o no
         setInputNecesitaFactura(true);
         //muestra o no el campo
         setFirstCallStatus(false)
      }
    }

    //Esta funcion defini si se muestra o no el componente para subir archivos

    useEffect (() => {
      
        if (isTrueSiEnBoca && isTrueUpload) {
          setActiveUploadImg("activemos");
          setnNedActive(true)
          setTextoBtnSend("Completa todos los campos")
        } else if ( isTrueSiEnBoca && activeLateralCraneo ) {
          setActiveUploadImg("activemos");
          setnNedActive(true)
          setTextoBtnSend("Completa todos los campos")
          
        } else if (noEnBocaClinica && isTrueUpload)  {
          setActiveUploadImg("activemos");
          setnNedActive(true)
          setTextoBtnSend("Completa todos los campos")
        }
        else {
          setActiveUploadImg(null);
          setnNedActive(false)
          setTextoBtnSend("Siguiente")
          
          
        }
       // console.log("ls datos es nuii", isTrueSiEnBoca, isTrueUpload)
      
},[isTrueUpload, isTrueSiEnBoca, noEnBocaClinica]);
    

    //On Factura
    const onFactura = (e) => {
      //console.log('radio checked', e.target.value);

      if (e.target.value === "true") {
        
        setFacturaNeed(true);
      } else {
        
        setFacturaNeed(false);
      }
      
    };

     //Oculta o muestra el area del form
     let changeclass = componentDisabled1 != null ? ' none-div' : '';
     let changeclassEnboca = casoEnBoca == null ? ' none-div' : '';
     //se muestra o no sección factura
     let hideShowFactura = firstCallStatus != false ? ' none-div' : '';
     //Oculta o muestra ya tomo planificación
     let hideShowIsFree = firstCallStatus != true ? ' none-div' : '';
    //Sete que tipo de head mostras, gratis o de paga
     let headTypeStart = headType != false ? ' none-div' : '';



    let activeUploadImgstart = activeUploadImg == null ? ' none-div' : '';

    //console.log(activeUploadImg);
    //console.log("aqui el datooooooooooooo");

    //Load BTN
    const [loadings, setLoadings] = useState([]);
    const enterLoading = (index) => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        
        newLoadings[index] = true;
        return newLoadings;
      });
 
      setTimeout(() => {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = false;
          //console.log("ya se envio");
          
          return newLoadings;         
        });
      }, 2000);
    };


 //Alertas
//modal de confirmación 

// Esta fncion define el modal de confirmacion que se mostrara si es Asesoría clinica o planificación

const whatModal=(e)=>{

  if (e === "Asesoría de planificación") {
    alertaSuccesPlanificacion()
   
  } else {
    alertaSuccesClinica();
  }

}


const alertaSuccesClinica=()=>{
  Swal.fire({
  title: "Importante",
  html: "Tendrás <span>10 minutos de tolerancia</span> para presentarte a tu asesoría. Después de esto daremos como finalizada la llamada y deberás agendar una nueva con costo.</br> Si lo necesitas, <span>puedes reagendar tu asesoría hasta con 48 horas de anticipación.</span>",
  imageUrl: iconImportant,
imageWidth: 60,
imageHeight: 60,
showCloseButton: false,
backdrop: `testss`,
allowEnterKey: false,
allowEscapeKey: false,
allowOutsideClick: false,
confirmButtonText: `Estoy de acuerdo`,
  customClass: {
      popup: 'popAlert',
      title: 'titlePopup',
      htmlContainer: 'textpopup',
      confirmButton: 'confirmBtn',
      closeButton: 'clodeBtnBtn'

  }
}).then((result) => {
  
  //window.location.reload();
  setActivForm("false");

   //esta constante nos sirve para validar si el se muestra o no calendario de paga aunque sea  admin 34
   const convertBolean = hasPlanificationUrl === "false" ? true : false;

  if (planificacin34 && seCobra) {
    setCalendlyPaga(true)
    //console.log("se debe estar mostrando calendly de paga")
   
  } else if (seCobra && convertBolean ) {
    //  muestra o no calendario de paga aunque sea  admin 34
    setCalendlyPaga(true)
    
  } 
  else {
    queCalendarMuestrs();
    //console.log ( seCobra + firstCallStatus + "sadfasdfasdfsdf")
  }
  
  
});
}


const alertaSuccesPlanificacion=()=>{
  Swal.fire({
  title: "Importante",
  html: "Tendrás <span>10 minutos de tolerancia</span> para presentarte a tu asesoría. Después de esto daremos como finalizada la llamada y deberás agendar una nueva sin costo.</br> Si lo necesitas, <span>puedes reagendar tu asesoría hasta con 24 horas de anticipación.</span>",
  imageUrl: iconImportant,
imageWidth: 60,
imageHeight: 60,
showCloseButton: false,
backdrop: `testss`,
allowEnterKey: false,
allowEscapeKey: false,
allowOutsideClick: false,
confirmButtonText: `Estoy de acuerdo`,
  customClass: {
      popup: 'popAlert',
      title: 'titlePopup',
      htmlContainer: 'textpopup',
      confirmButton: 'confirmBtn',
      closeButton: 'clodeBtnBtn'

  }
}).then((result) => {
  
  //window.location.reload();
  setActivForm("false");

   //esta constante nos sirve para validar si el se muestra o no calendario de paga aunque sea  admin 34
   const convertBolean = hasPlanificationUrl === "false" ? true : false;

  if (planificacin34 && seCobra) {
    setCalendlyPaga(true)
    //console.log("se debe estar mostrando calendly de paga")
   
  } else if (seCobra && convertBolean ) {
    //  muestra o no calendario de paga aunque sea  admin 34
    setCalendlyPaga(true)
    
  } 
  else {
    queCalendarMuestrs();
    //console.log ( seCobra + firstCallStatus + "sadfasdfasdfsdf")
  }
  
  
});
}

//Define que calendario mostrar

function queCalendarMuestrs () {
  //console.log("veamos que esta pasando" + seCobra )

  const isPLanification = activeLateralCraneo === false ? true : false;

  if ( isPLanification && calendlyPlanificacin17 ) {
    setActivCalendly(null);

    setPlanificacin17(true);
    
  }  else if (isPLanification && calendlyPlanificacin14) {
    setActivCalendly(null);

    setPlanificacin14(true);
  } 
  else {
    setActivCalendly("true");
    
  }
}


let muestraCalendly = activCalendly === null ? ' none-div' : '';
let ocultaForm = activForm === "false" ? ' none-div' : '';

//modal de error
const alertaError=()=>{
  Swal.fire({
  title: "Algo salió mal...",
  html: "Hemos tenido un problema para cargar la información necesaria para agendar tu asesoría. Por favor vuelve a intentarlo. ",
  imageUrl: AlertError,
imageWidth: 60,
imageHeight: 60,
showCloseButton: false,
showConfirmButton: false,
backdrop: '#F6F6F8',
allowEnterKey: false,
allowEscapeKey: false,
allowOutsideClick: false,
confirmButtonText: `Volver a intentarlo`,
  customClass: {
      popup: 'popAlert',
      confirmButton: 'btn-siguiente',
      title: 'titlePopup',
      htmlContainer: 'textpopup',
      closeButton: 'clodeBtnBtn',
      

  }
}).then((result) => {
  
});
}

//modal asesoria debe cobrarse

const alertaPay=()=>{
  Swal.fire({
  title: "Antes de continuar",
  html: "<ul><li>Ya tuviste tu asesoría clínica gratuita, por lo que <span>esta asesoría tendrá un costo de $425 MXN.</span></li><li>Asegúrate de que el Perfil Fiscal que tienes configurado como predeterminado en el Portal B360 <strong>sea el que deseas utilizar para facturar tu asesoría.</strong></li><li>Si no has establecido un Perfil Fiscal predeterminado aún, puedes hacerlo desde tu Perfil en el Portal B360; de lo contrario no te generaremos factura.</li></ul>",
  
  imageUrl: AlertError,
imageWidth: 60,
imageHeight: 60,
showCloseButton: false,
showConfirmButton: true,
allowEnterKey: false,
allowEscapeKey: false,
allowOutsideClick: false,
confirmButtonText: `Continuar`,
  customClass: {
      popup: 'popAlert',
      confirmButton: 'confirmBtn',
      title: 'titlePopup',
      htmlContainer: 'textpopup-pay',
      closeButton: 'clodeBtnBtn',
      

  }
});
}

useEffect(() => {
 if (oclusalSuperior && oclusalInferior && FrontalSinAlineador && frontalConAlineador && LateralDerechaOclusion && lateralIzquierdo && panoramicaCraneo && areaConsulta   ) {
  //console.log("si tenemos algo")
    setnNedActive(false)
    setTextoBtnSend("Siguiente")
 }
  
  
}, [oclusalSuperior, oclusalInferior, FrontalSinAlineador, frontalConAlineador, LateralDerechaOclusion, lateralIzquierdo, panoramicaCraneo, areaConsulta ]);


async function registrandoAsesoria(e) {
  
  //console.log("aqui estan los datos del form " + e)
  enterLoading(0);
  try {
      const response = await fetch('https://api.airtable.com/v0/appHsHG762lLNWvtr/tblJAIDAKMMtwGX39', {
      method: 'POST',
      headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          'Authorization': 'Bearer patIWUuGVMhjM6ChE.41b3f1abc3795a2e56b25b0f07e78eba8eb745cd988484f3ce07e525a143da49',
      },
      body: JSON.stringify({
          "records": [{
              "fields": {
                  "fld47enEc8mERiaGA": tratmentIdUrl, //Tratment Id
                  "fldFwereJT6gt200o": adminIdUrl, //Id Admin
                  "fldftOSA2WktZLZly": hasPlanificationUrl, //Tiene planificación
                  "fldSnX8x69DN6Fiur": e.ElcasoSeEncuentra, //El caso se ecnuentra
                  "fldsCZjm564gdNVgi": e.IndicaAlineador, //Alineador
                  "fldojtzk8egAZSRSR": tipoDeAsesoriaApiPost, //Tipo de asesoría
                  "fldLHNajCHT0TiTEG": e.TextArea, //Mensaje
                  "fldyc9cZ56SFyhx9F": seCobra, //Tomo asesoría gratutita?
                  "fldLX3ThXbADUNWVK": facturaNeed, //Necesita factura     
                  "fldvqEwaivJsemQI2": oclusalSuperior.uno, //Ocular superurir
                  "flds3KaQIvoJveeFK": oclusalInferior, //Ocular Inferior
                  "fldZO4I3PzPUfleB7": frontalSinAlineador, //Frontal sin alineador
                  "fldFCAEckElCAkIJv": frontalConAlineador, //Frontal con alineador
                  "fldTNpCcgNchP922O": lateralDerecho, //Frontal con Lateral derecho
                  "fldkgLtSmqx7QO8pg": lateralIzquierdo, //Frontal con Lateral Izquierdo
                  "fldM0bsdXbONDPt04": panoramicaCraneo, //Frontal con Panoramica craneo
                  "fld0u2LXuzJYwV7PA": lateralCraneo, //Frontal con Lateral craneo
                  "fldpqvTUDPpVp7JnF": areaConsulta, //Frontal con Area motivo consulta
      }}],

          "typecast": true

      })
  });

  //console.log(response);
  
  if (response.status === 200) {
    //alertaSucces();
    
    sedRequestInvoice(facturaNeed);

    whatModal(tipoDeAsesoriaApiPost);

    
  } 
   else {
      alertaError(); 
  }
      
  } catch (error) {
      //console.log(error)      
  }  
}

async function sedRequestInvoice(sabesmoSiNo ) {
  
    const getUrl= window.location.search;
        const separateUrl = getUrl.split("&");
        setFirstCallUrl(separateUrl[3]);
        
if (sabesmoSiNo === true) {
  //console.log("estamos solicitando factura")
  const BORGATTA_API_URL = "https://api.b360.mx"; // our API base url.
  //var treatmentId = tratmentIdUrl; // some example.

  const requestOptions = {
    method: "POST",
  };
  
fetch(`${BORGATTA_API_URL}/api/clinical_advice/create_invoice/${tratmentIdUrl}`, requestOptions)

  .then((response) => response.text())
  .then((result) => console.log("aca la respuesta si factura", result))
  .catch((error) => console.error(error));
} else {
  //console.log("No estamos solicitando factura")
}
       
}

return (
        <div className='wrapp-form-asesorias'>
            <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#EB9542',
              borderRadius: 2,
              buttonCheckedBg: '#00b96b',
              
            },
            button: {
              borderColorDisabled: '#000000',
            }
          }}
          
        >

            <Form 
            className={`form-asesorias${ocultaForm}`} 
            
            onFinish={registrandoAsesoria}
            scrollToFirstError
            >
            {/* header si ya tomo asesoría gratuita */}
            
            <div
            className={`head-form`}
            >
               <h2 className='head-master-form'>Agenda tu asesoría</h2>
               <p className='info-text'>Ingresa la información para agendar tu asesoría <span  className={`test${headTypeStart}`}>gratuita</span>.</p>
            </div>
            
            {/* Wrapp form part checkbox and inputs */}
            <div className='wrapRadioBtns' 
            >
            {/* El caso se encuentra en */}
            <div className={`wrapp-radio-group${changeclass}`}>
                <p className='text-head-group-radio'>El caso se encuentra</p>
                <Form.Item
                className={`wrapp-each-item-form ${ocultaForm}`} 
                name="ElcasoSeEncuentra"
                rules={[{ required: componentNoRequerid, message: 'Elije una opcion' }]}
                >
                <Radio.Group 
                
                onChange={onElCasoSeEncuentra}  
                value={value} 
                options={seEncuentra}  
                className='text-radio'>        
                </Radio.Group>
                </Form.Item>   
            </div>
            {/* En bocan */}
            <div className={`wrapp-radio-group${changeclassEnboca}`}>
                <p className='text-head-group-radio'>El caso se encuentra en el alineador </p>
                <Form.Item
                className='wrapp-each-item-form'
                name="IndicaAlineador"
                rules={[{ required: inputAlineador, message: 'Ingrese el alineador' }]}
                >
                <Input className='input-text' placeholder="Indica el alineador" />
                </Form.Item>  
            </div>

            {/* Tipo de asesoria */}
            <div className={`wrapp-radio-group${changeclass}`}>
                <p className='text-head-group-radio'>Tipo de asesoría</p>
                <p className='info-text'>Selecciona la asesoría que mejor se adapte al motivo de consulta.</p>
                <Form.Item
                className='wrapp-each-item-form'
                name="TipoDeAsesoria"
                rules={[{ required: inputTasesoria, message: 'Elije una opcion' }]}
                >   
                  <Radio.Group onChange={onChanges}  value={values} options={tipoAsesoria} className='text-radio'>           
                  </Radio.Group>
                </Form.Item>
                
            </div>

            {/* Motivo de consulta */}
            <div className='wrapp-radio-group'>
            <p className='text-head-group-radio'>Motivo de consulta</p>
            <p className='info-text'>Detalla a profundidad el motivo de consulta. Esto facilitará la asesoría.</p>
            <Form.Item
            className='wrapp-each-item-form'
            name="TextArea"
            rules={[{ required: true, message: 'Campo obligatorio' }]}
            >      
            <Input.TextArea  rows={5} placeholder="Indica el motivo de consulta" />
           
            </Form.Item>
            </div>
            {/* Necesita factura? */}
            <div className={`wrapp-radio-group${hideShowFactura}`}>
                <p className='text-head-group-radio'>¿Necesita facturar tu asesoría?</p>
                <Form.Item
                className='wrapp-each-item-form'
                name="NecesitaFactura"
                rules={[{ required: inputNecesitaFactura, message: 'Elije una opcion' }]}
                >  
                    <Radio.Group onChange={onFactura} value={values} options={factura} className='text-radio'>       
                    </Radio.Group>  
                </Form.Item>
                <div
                style={{
                  marginBottom: '40px'
              }}
                >
                <p className='info-text-red'>*Si en el Portal B360 no tienes un Perfil Fiscal predeterminado, no se generará tu factura.</p>
                </div>
                
               
                
            </div>
            </div>

            

            {/* Wrapp form part upload */}
            <div 
            style={{
            }}>
            {/* Subida de archivos */}
            <div className={`${activeUploadImgstart}`}>
            <p className='text-head-group-radio'>Subida de archivos</p>
            <div className='alert-upload-img'>
                <div>
                    <img src={alerticon} alt="" />
                </div>
                <div className='wrapp-text-alert-img-upload'>
                    <p className='info-text-strong'>Tus archivos deben ser recientes</p>
                    <p className='info-text'>Asegúrate de que los archivos sean actuales (que tengan menos de 7 días) para brindarte la mejor asesoría posible.</p>
                </div>
            
            </div>
            
            
            <div className='wrapp-upload-content'>
            <UploadFile URLimage={URLimage} />
            <Oclusalinferior URLOclusalinferior={URLOclusalinferior}  />
            <FrontalSinAlineador URLFrontalSinAlineador={URLFrontalSinAlineador} />
            <FrontalConAlineador URLFrontalConAlineador={URLFrontalConAlineador} />
            <LateralDerechaOclusion URLLateralDerechaOclusion={URLLateralDerechaOclusion} />
            <LateralizquierdaOclusion URLLateralizquierdaOclusion={URLLateralizquierdaOclusion} />
            <PanoramicaCraneo URLPanoramicaCraneo={URLPanoramicaCraneo} />
            { activeLateralCraneo && <LateralCraneo URLLateralCraneo={URLLateralCraneo} />}
            <AreaMotivoConsulta URLAreaMotivoConsulta={URLAreaMotivoConsulta} />
            </div>
            

            </div>
            </div>
            <Form.Item >
                <Button 
                className='btn-siguiente'
                type="primary" 
                htmlType="submit" 
                loading={loadings[0] } 
                disabled={needActive}
                >
                  {textoBtnSend}
                </Button>
            </Form.Item>
            </Form>
            </ConfigProvider>
            <div className={`${muestraCalendly}`}>
            <CalendlyOneWrapp />
            
            </div> 
            { calendlyPaga && <CalendlyAsesoriasPaga />}
            { planificacin17 && <CalendarPlanificacion17 />}
            { planificacin14 && <CalendarPlanificacion14 />}
       
        </div> 
    )
  
};

export { FormAsesorias };
