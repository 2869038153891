import React, { useEffect } from 'react';
import './calendarStyles.css';




function CalendarPlanificacion17 () {

    return (

        <>
        <div>
               <h2 className='head-master-form'>Agenda tu asesoría</h2>
               <p className='info-text'>Selecciona una fecha para agendar tu asesoría.</p>
            </div>
            <iframe
        className='CalendarStyle' 
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ12ELsTUl0d1r9Lkqio6pG2T5OYZ-opHhrANr9M5b-aTwcj9BJTO6h2-AucJY09TYjIczwSyQpb?gv=true">

        </iframe>
        </>

    )
    
}

export { CalendarPlanificacion17 }