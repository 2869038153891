import React, { useEffect } from 'react';
import { InlineWidget } from 'react-calendly';
import './calendarStyles.css';




function CalendlyOneWrapp () {

    return (

        <>
        <div>
               <h2 className='head-master-form'>Agenda tu asesoría</h2>
               <p className='info-text'>Selecciona una fecha para agendar tu asesoría.</p>
            </div>
        <iframe
        className='CalendarStyle' 
        src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ37Pgd7O6G06k51ylPwsK1pAip_RcX1S6h8zyeFqTp52nv-Ye5a7YVs8V-DQt2u3KGljccVn-5d?gv=true">

        </iframe>

        </>

    )
    
}

export { CalendlyOneWrapp }

