import React, { useEffect } from 'react';
import { InlineWidget } from 'react-calendly';
import './calendarStyles.css';




function CalendlyAsesoriasPaga () {

    return (

        <>
        <div>
               <h2 className='head-master-form'>Agenda tu asesoría</h2>
               <p className='info-text'>Selecciona una fecha para agendar tu asesoría.</p>
            </div>
            <iframe
        className='CalendarStyle' 
        src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3Tv5huwfkiRChU1kwDNLLksDVsl9p5OjJh2nu4oQk4Ex31Piz6dymbB1hqowT89ZFsxvH33QDb?gv=true">

        </iframe>
        </>

    )
    
}

export { CalendlyAsesoriasPaga }

